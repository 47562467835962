import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql } from 'gatsby';
import React from 'react';
import LoadRenewalWrapper from 'components/ApiRequestWrapper/LoadRenewalWrapper';
import Layout from 'components/Layout';
import QuoteSummaryPage from 'components/QuoteSummary';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { useQuote } from 'state/quote/quote';
import { useUser } from 'state/user/state';
import { CsHero, CsMeta } from 'types/contentStack';
import { PriceBannerWhitespace } from './styles';

type QuoteSummaryProps = {
  data: {
    csQuoteSummary: {
      meta: CsMeta;
      hero_renewal: CsHero;
    };
  };
};

export const query = graphql`
  query {
    csQuoteSummary {
      meta {
        meta_title
      }
      hero_renewal {
        heading
        subheading
      }
      sticky_footer {
        monthly_text
        yearly_text
      }
    }
  }
`;

const QuoteSummary: React.FC<QuoteSummaryProps & PolicyNumberProps> = ({
  data: {
    csQuoteSummary: {
      meta,
      hero_renewal: { heading, subheading },
    },
  },
  policyNumber,
}) => {
  const quote = useQuote(true);

  const [user] = useUser();
  usePageTracking(meta.meta_title, !!user?.isLoggedIn && !!quote);

  useCheckoutTracking(5, quote, 'Renewals', true);

  return (
    <>
      <Layout meta={meta} pageType="mtaAndRenewal">
        <LoadRenewalWrapper policyNumber={policyNumber}>
          {quote && (
            <>
              <HeroPlain heading={heading} subhead={subheading || undefined} />
              <QuoteSummaryPage quote={quote} />
            </>
          )}
        </LoadRenewalWrapper>
      </Layout>
      {/* Need to create a space at the bottom of the page for the price banner so that it does not sit over the footer */}
      <PriceBannerWhitespace />
    </>
  );
};

export default withPolicyNumber(QuoteSummary);
